<template>
  <b-row align-v="center" align-h="center">
    <b-col cols="8">
      <b-card title="Nuovo utente">
        <validation-observer ref="registerForm">
          <b-container fluid>
  <!-- required fields -->
            <b-row class="my-1" v-for="(val,key) in userKeysMap" :key="key">

              <b-form-group
                class="col-12"
                :label="val.text"
                :label-for="`register-${key}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="val.text"
                  :vid="key"
                  :rules="val.rules"
                >
                  <b-input-group
                    v-if="key == 'password' || key == 'repassword'"
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      :id="`register-${key}`"
                      v-model="utente[key]"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-input
                    v-else
                    :id="`register-${key}`"
                    v-model="utente[key]"
                    :type="val.type"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-col sm="3">
                <label :for="`input-${key}`">{{ val.text }}:</label>
              </b-col>
              <b-col sm="9">
                <b-form-input
                  :id="`input-${key}`"
                  v-model="utente[key]"
                  :type="val.type"
                  :state="utente[key] ? utente[key].length > val.state : null"
                />
                <b-form-invalid-feedback :id="`input-${key}-feedback`">
                  {{ val.feedback}}
                </b-form-invalid-feedback>
              </b-col> -->
            </b-row>
  <!-- optional toggle -->
            <!-- <b-row align-h="end">
              <b-toggle v-model="optionalFields">
                Altri dati
              </b-toggle>
            </b-row> -->
  <!-- optional fields absent -->
            <!-- <template v-if="optionalFields">
              <b-row class="my-1" v-for="(val,key) in userKeysMap" :key="key">
                <b-col sm="3">
                  <label :for="`input-${key}`">{{ val.text }}:</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    :id="`input-${key}`"
                    v-model="utente[key]"
                    :type="val.type"
                    :state="utente[key] ? utente[key].length > val.state : null"
                  />
                  <b-form-invalid-feedback :id="`input-${key}-feedback`">
                    {{ val.feedback}}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </template> -->
          </b-container>

          <b-row align-h="end">
            <b-button
              class="m-1"
              :variant="`${isDark ? 'warning' : 'primary'}`"
              @click="register"
            >
              {{ 'Salva' }}
            </b-button>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BCard, BContainer, BFormInvalidFeedback,
  BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useAppConfig from '@core/app-config/useAppConfig'
// import { computed } from '@vue/composition-api'
import { computed } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BCard,
    BContainer,
    BFormInvalidFeedback,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { isDark }
  },
  data() {
    return {
      status: '',
      utente: {
        username: '',
        email: '',
        password: '',
        repassword: ''
      },
      optionalFields: false,
      userKeysMap: {
        username: {
          text: 'Username',
          rules: 'required|min:6',
          type: 'text',
          state: 2,
          feedback: 'Inserisci almeno 6 caratteri'
        },
        email: {
          text: 'Email',
          rules: 'required|email',
          type: 'text',
          state: 2,
          feedback: 'Inserisci un\'email valida'
        },
        password: {
          text: 'Password',
          rules: 'required|min:8',
          // rules: { required: true, regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^a-zA-Z\d\s:])[A-Za-z\d@$!%*#?&,.;:ç°§_%\\"£()[\]{}-]{8,}$/ },
          type: 'text',
          state: 2,
          // feedback: 'Inserisci password con almeno 8 caratteri di cui un numero, di cui un simbolo'
          feedback: 'Inserisci password con almeno 8 caratteri'
        },
        repassword: {
          text: 'Ripeti password',
          rules: 'required|confirmed:password',
          type: 'EyeIcon',
          state: 14,
          feedback: 'Ripeti inserimento password'
        },
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          let req = this.buildSaveObject()
          this.$http.get('/az/getDetails').then(res => {
            req['email_aziendale'] = res.indEmailC
          })
          this.$http.post('/az/registerUser', req).then(res => {
// console.log(res);
            if (res && res.data.success) {
              this.titolo = ''
              this.body = ''
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'User',
                  text: 'Utente inserito con successo',
                  icon: 'UserIcon',
                  variant: 'success',
                },
              })
              this.$router.push('users')
            } else {
              // console.log(res.data.e.errors[0].message);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Errore',
                  // LOCALIZE text: error.message
                  text: res.data.e.errors[0].message,
                  icon: 'AlertTriangleIcon',
                  variant: 'error',
                },
              })
            }
          })
          .catch(error => {
            // console.warn(error.response.data);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Errore',
                text: error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'error',
              },
            })
          })
        }
      })
      .catch(error => {
        // console.log(error);
        this.$refs.registerForm.setErrors(error.response.data.error)
      })
    },
    buildSaveObject() {
  // if !optionalFields remove
      const entries = Object.entries(this.utente)
      const nonEmptyOrNull = entries.filter(([key,val]) => val !== '' && val !== null)
      const output = Object.fromEntries(nonEmptyOrNull)
      return output
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
